import entityPresenter from '../../entity/views/entityPresenter';
import journalPresenter from '../../entity/views/journalPresenter';
import MultilingualString from '../models/multilingualString';
import {urls} from '../service/linkBuilder';
import {buildDefaultPopover} from './utils'
import ErrorCode from '../enums/errorCode';
import ConfirmModal from './confirmModal'

function getLoader() {
	return `
		<div class="loading" style="width: 100%; height: 100%; position: absolute; top: 0; left: 0;">
			<div  class="preview-area" >
				<div class="spinner">
					<div class="dot1"></div>
					<div class="dot2"></div>
				</div>
			</div>
		</div>`
}

function updateTheme(tab) {
	let themeUrl = tab && tab.presentationContext && tab.presentationContext.themeUrl;
	let previousUrl = $('#cssTheme').attr('href')
	if (themeUrl !== undefined && themeUrl !== previousUrl){
		if (themeUrl) {
			$('#cssTheme').attr('href', themeUrl);
		} else {
			$('#cssTheme').removeAttr('href');
		}
	}
}

const getNextId = (() => {
	let id = 0;
	return () => ++id;
})();


class CjTab {
		constructor(options) {
			this.id = getNextId();
			this.tabs = options.tabs;
			this.parent = options.parent;
			this.history = [];
			this.init(options);
		}

		init(options) {
			if (options.onRender) {
				this.onRender = options.onRender;
			}
			this.callerModel = options.exactParent;
			this.url = options.url;
			this.title = options.title;
			this.$el = options.$el;
			this.presentationInfo = options.presentationInfo;
			this.showLoader = options.showLoader;
			if (this.presentationInfo) {
				this.presentationInfo.onClose = this.presentationInfo.onClose
					|| (() => this.tabs.checkBeforeClose(this));
				const currentAfterFormLoaded = this.presentationInfo.afterFormLoaded;
				this.presentationInfo.afterFormLoaded = () => {
					this._initToolbar();
					buildDefaultPopover($('.popover-tooltip'),(window.matchMedia("(max-width: 767px)").matches)?{placement:  'bottom'}: {})
					return currentAfterFormLoaded ? currentAfterFormLoaded() : Promise.resolve();
				};
			}
		}

		_initToolbar() {
			const toolbar = this.$el.find('.fixed-toolbar').first();
			this.$el.prepend(toolbar);
			if (app.withoutHeader) {
				toolbar.removeClass('affix-top').addClass('affix')
				toolbar.on('affixed-top.bs.affix', () => { $('.fixed-toolbar').removeClass('affix-top').addClass('affix')})
			}
			toolbar.affix({offset:{top:50}});
		}

		async render() {
			if (!this.$el) {
				this.$el = $('<div class="cj-tab">');
				if (this.showLoader) {
					this.$el.append(getLoader());
					this.$el.addClass('hold-transition');
				}
				if (!this.onRender) {
					this.$el.append(`
						<div class="content">
							<div class="form">
								<div class="forms-container">
							</div>
						</div>`);
				}
				this.tabs.$el.append(this.$el);
			}
			if (this.onRender) {
				this.onRender(this);
			}
			if (this.presentationInfo) {
				this.presentationInfo.afterCreated = this.presentationInfo.afterCreated
					|| (ctx => window.history.replaceState(null, document.title,
						app.urls.update(ctx.type.id, ctx.objectId)));
				if (this.showLoader) {
					this.presentationInfo.hideLoading = () => this._hideLoading();
				}
				try {
					this.presentationContext = await entityPresenter.present(
						_.extend(this.presentationInfo, {
						el: this.$el,
						toolbar: this.$el.find('.fixed-toolbar'),
						ignoreBlocks: app.builderMode,
						viewControl: this
					}));
					this.title = this.title || this.presentationContext.title;
				} catch (e) {
					if (e.message == ErrorCode.META_DATA_IS_STALE) {
						app.notificationManager.addError(
							MultilingualString.fromStringInCurrentLanguage(translate('stale.meta.data'))
						);
						this.close();
					} else {
						throw e;
					}

				}
			}
		}

		_hideLoading() {
			this.$el.find('.loading').fadeOut('fast')
			setTimeout(() => {
				this.$el.removeClass('hold-transition');
				this.$el.find('.loading').remove();
			}, 100);
		}

		stopPresentation() {
			if (this.presentationContext) {
				this.presentationContext.destroy();
			}
		}

		destroy() {
			this.stopPresentation();
			this.$el.remove();
		}

		close() {
			this.tabs.closeTab(this);
		}

		isPresenation() {
			return this.presentationContext != null;
		}
}


class CjTabSelector {
	constructor(cjTabs) {
		this.tabs = cjTabs;
		this.$el = $('<div class="tab-selector">').prependTo(cjTabs.$el);
		this.$el.on('click', '.close-tab', (e) => {
			this.$el.find('.tab-link').popover('hide');
			this.tabs.tryCloseTabById($(e.currentTarget).attr('data-tab-id'));
		});
	}

	addTab(tab) {
		const el = $('<div class="tab-link" />').appendTo(this.$el);
		el.attr('data-tab-id', tab.id);
		el.click(() => {
			this.tabs.activateTab(tab);
		});
		buildDefaultPopover(el, {
			trigger: 'manual',
			placement: 'bottom',
			html: true,
			content: () => this.getPopover(tab),
			animation: false,
		})
		this._updateVisability();
	}

	getPopover(tab) {
		let html = `<span>${tab.title}</span>
				<button class="btn btn-link close-tab" data-tab-id="${tab.id}">
					<i class="fa fa-times"></i>
				</button>`;
		return html;
	}

	activate(tab) {
		updateTheme(tab);
		const el = this.$el.find(`[data-tab-id="${tab.id}"]`);
		this.$el.find('.active').removeClass('active');
		el.addClass('active');
	}

	remove(tab) {
		this.$el.find(`[data-tab-id="${tab.id}"]`).remove();
		this._updateVisability();
	}

	_updateVisability() {
		if (this.tabs.tabs.length > 1) {
			this.$el.show();
		} else {
			this.$el.hide();
		}
	}

}

class CjTabs {
	constructor() {
		this.$el = $('.cj-tabs');
		this.tabs = [];
		this.activeTab = null;
		this.selector = new CjTabSelector(this);
		$(window).on('popstate', () => {
				const prev = this.activeTab.history.pop();
				if (prev) {
					this._replaceTabContent(this.activeTab, prev);
				} else {
					if (this.tabs.length > 1) {
						this.checkBeforeClose(this.activeTab);
					}
				}
		});

	}

	createPresentationTab(url, callback, opts) {
		opts = opts || {};
		this.createTab({
			showLoader: true,
			url: url,
			presentationInfo: _.extend(opts, {
				url: url,
				afterSaved: callback
			})
		})
	}

	createTab(options) {
		options.tabs = this;
		options.parent = this.activeTab;
		const tab = new CjTab(options);
		this.tabs.push(tab);
		tab.render().then(() => this._updateTitle());
		this.selector.addTab(tab);
		this.activateTab(tab);
	}

	_updateTitle() {
		if(this.activeTab.title) {
			document.title = this.activeTab.title;
		}
	}

	activateTab(tab, prevInfo) {
		if (this.activeTab) {
			this.activeTab.savedScroll = window.scrollY;
		}
		this.activeTab = tab;
		window.history.pushState(prevInfo, tab.title, tab.url);
		this._updateTitle();
		this.$el.find('.cj-tab').hide();
		tab.$el.show();
		tab.presentationContext && tab.presentationContext.update();
		this.selector.activate(tab);
		if (tab.savedScroll) {
			window.scroll(0, tab.savedScroll);
			tab.savedScroll = null;
		}
	}

	showInCurrentTab(url, opts) {
		if (this.activeTab.isPresenation()) {
			this.activeTab.history.push(this.activeTab.presentationInfo.url);
		}
		this._replaceTabContent(this.activeTab, url, opts);
		this.activateTab(this.activeTab);
	}

	_replaceTabContent(tab, url, opts) {
		opts = opts || {};
		tab.stopPresentation();
		tab.init({
			url: url,
			presentationInfo: _.extend(opts, {
				url: url,
			})
		});
		tab.render().then(() => this._updateTitle());
	}

	canShow() {
		return !$('body').hasClass('editing-instance');
	}

	closeTab(tab) {
		let tabToActivate;
		if (tab == this.activeTab) {
			tabToActivate = this.activeTab.parent;
			if (!tabToActivate || this.tabs.indexOf(tabToActivate) === -1) {
				tabToActivate = this.tabs[this.tabs.length-1];
				if (tabToActivate === tab) {
					tabToActivate = this.tabs[this.tabs.length-2];
				}
			}
		}
		this.tabs.splice(this.tabs.indexOf(tab), 1);
		this.selector.remove(tab);
		tab.destroy();
		if (tabToActivate) {
			updateTheme(tabToActivate);
			this.activateTab(tabToActivate);
		}
	}

	checkBeforeClose(tab) {
		if (tab.preventPageLeave && tab.preventPageLeave.predicate && tab.preventPageLeave.predicate()) {
			var modal = new ConfirmModal()
			modal.show({
				resource: 'unstaged.changes',
				buttons: {
					'save': (e) => {
						tab.preventPageLeave.save(e)
					},
					'continue': () => {}
				},
				then: () => {
					tab.preventPageLeave.removeEventHandler && tab.preventPageLeave.removeEventHandler()
					this.closeTab(tab)
			}})
		}
		else {
			this.closeTab(tab)
		}
	}

	tryCloseTabById(id) {
		const tab = _.find(this.tabs, tab => tab.id === +id);
		this.checkBeforeClose(tab);
	}

	tryCloseCurrentTab() {
		this.checkBeforeClose(this.activeTab);
	}

};


export default new CjTabs();
